import React, {Component, Suspense} from "react";
import Lottie from "react-lottie";
import Loading from "../../containers/loading/Loading";
// add a link to the css file SoftwareSkill.scss
import "../../components/softwareSkills/SoftwareSkill.scss";
export default class DisplayLottie extends Component {
  render() {
    const animationData = this.props.animationData;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData
    };
{/* resizeMode="cover" style={{
    width: '100%',
    maxHeight: '70vh',
    marginLeft: '-17%',
    marginTop: '-7%',}} */}
    return (
      <Suspense fallback={<Loading />}>
        {/* <div style={{maxHeight: "50vh", display: 'flex', alignItems: 'center'}}> */}
        <div className="animation" > 
        {/* style={{
              height: "auto",
              margin: "-20vh auto"
            }} */}
        <Lottie options={defaultOptions} isClickToPauseDisabled={true}/>
        </div>
          
          
        {/* </div> */}
        {/* <Lottie options={defaultOptions} isClickToPauseDisabled={true}/>  */}
        
      </Suspense>
    );
  }
}
