import React, {useState, useEffect, useContext, Suspense, lazy} from "react";
import "./Project.scss";
import Button from "../../components/button/Button";
import {openSource, socialMediaLinks} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import Loading from "../../containers/loading/Loading";
import {Fade} from "react-reveal";

export default function Projects() {
  const GithubRepoCard = lazy(() =>
    import("../../components/githubRepoCard/GithubRepoCard")
  );
  const FailedLoading = () => null;
  const renderLoader = () => <Loading />;
  const [repo, setrepo] = useState([]);
  // todo: remove useContex because is not supported
  const {isDark} = useContext(StyleContext);

  const projects = [
    {
      id: 9,
      title: "Fluxus (HackMIT Winner)",
      subtitle:
        "Won 1st place in 2 tracks at HackMIT out of 1070+ participants by developing an end to end data workspace. Utilized LLMs to process natural speech into SQL and transform datasets into iterable dataframes visualized as Directed Acyclic Graphs.",
      image: require("../../assets/images/fluxusSS.jpeg"),
      link: "https://devpost.com/software/fluxus-a4bgv1",
      languages: [
        "Python",
        "Flask",
        "VueJS",
        "LLMs",
        "InterSystems-IRIS",
        "Firebase"
      ]
    },
    {
      id: 8,
      title: "mentis.ai (TreeHacks Winner)",
      subtitle:
        "Placed 2nd at Stanford’s hackathon by developing a teaching platform capable of generating an interactive video with audio on any topic and dynamically changing the rest of the content based on user’s real-time Q&As using fine-tuned LLMs.",
      image: require("../../assets/images/mentisSS.jpeg"),
      link: "https://devpost.com/software/ai-thena",
      languages: ["Python", "JavaScript", "NextJS", "FastAPI", "Bun"]
    },
    {
      id: 7,
      title: "fishbowl (HackTX Winner)",
      subtitle:
        "Won 1st place in Capital One’s Best Financial Hack at HackTX out of 620+ participants by designing and coding an AI-powered investment platform for startups. Devised a TikTok-style feed algorithm and an equity financial agreement.",
      image: require("../../assets/images/fishbowlSS.jpeg"),
      link: "https://devpost.com/software/fishbowl-47ntbw",
      languages: ["Dart", "Flutter", "JavaScript", "React", "LLMs", "Firebase"]
    },
    {
      id: 1,
      title: "StockSentry",
      subtitle:
        "A risk analysis mobile application that employs quantitative models to calculate the portfolio's Value at Risk, considers economic factors, and utilizes Natural Language Toolkit (NLTK) Vader to conduct sentiment analysis on financial sources.",
      image:
        "https://imageio.forbes.com/specials-images/imageserve/617ab453e95e58ee7ce7de16/Stock-market-investment-and-anxious-future/960x0.jpg?height=473&width=711&fit=bounds",
      link: "https://github.com/lingfeishengtian/StockSentry",
      languages: ["JavaScript", "Python", "React Native", "Flask", "NLTK Vader"]
    },
    {
      id: 2,
      title: "EcoStride",
      subtitle:
        "A cross-platform app that combines Google Maps API and a pedometer to track user steps and location. It also incorporates the OpenAI API for an EcoSearch suggestion tool. Additionally, a React website is connected to a Firebase backend, ensuring smooth integration between the app and the website.      ",
      image: require("../../assets/images/ecostridemore.png"),
      link: "https://github.com/batyr0601/ecostride",
      languages: ["Flutter", "Dart", "JavaScript", "React", "Firebase"]
    },
    {
      id: 3,
      title: "MoodMeter (APPtitude Winner)",
      subtitle:
        "Developed a web app to track mental health and emerged as the winner of a hackathon among teams from 13+ countries with a prize of $1000.",
      image: require("../../assets/images/moodmeter.png"),
      link: "https://github.com/n-kly/Apptitude-MoodTracker",
      languages: ["JavaScript", "React"]
    },
    {
      id: 4,
      title: "Huffman Encoder",
      subtitle:
        "Using Huffman Coding Algorithm, developed a compression software for any file type achieving up to 43% compression on text files and 20% on images.",
      image:
        "https://www.techiedelight.com/wp-content/uploads/2016/11/Huffman-Coding-6.png",
      link: "",
      languages: ["Java"]
    },
    {
      id: 5,
      title: "Monte Carlo Simulation",
      subtitle:
        "Ran 10,000,000+ simulations, visualized the results, and analyzed the accuracy and efficiency of various Monte Carlo methods at approximating Euler’s number.",
      image: require("../../assets/images/montecarlo.png"),
      link: "https://github.com/batyr0601/montecarlo",
      languages: ["Python", "NumPy", "Matplotlib"]
    },
    {
      id: 6,
      title: "AI Handwriting Classifier",
      subtitle:
        "Developed a model to identify user’s drawings into specific letters and digits (91% and 99% accuracy) and connected to front-end with a canvas using Flask.",
      image: require("../../assets/images/handwritingAI.png"),
      link: "https://github.com/batyr0601/handwritingAI",
      languages: ["Python", "TensorFlow", "JavaScript", "Flask", "React"]
    }
    // Add more projects as needed
  ];

  const ProjectCard = ({project}) => {
    const {title, subtitle, image, link, languages} = project;

    return (
      // <Fade bottom duration={1000} distance="20px">
      <div className="github-project-card">
        <img src={image} alt={title} />
        <h3>{title}</h3>
        <p>{subtitle}</p>
        <div className="languages-used">
          {languages.map((language, index) => (
            <span key={index} className={`language ${language.toLowerCase()}`}>
              {language}
            </span>
          ))}
        </div>
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
        )}
      </div>
      // </Fade>
    );
  };

  return (
    <Suspense fallback={renderLoader()}>
      <div className="main" id="opensource">
        <h1 className="project-title">Projects</h1>
        <div className="repo-cards-div-main">
          <div className="project-grid">
            {projects.map(project => (
              <ProjectCard key={project.id} project={project} />
            ))}
          </div>
        </div>
        <Button
          text={"More Projects"}
          className="project-button"
          href={socialMediaLinks.github}
          newTab={true}
        />
      </div>
    </Suspense>
  );
}
