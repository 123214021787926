import React, { useContext } from "react";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/landingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";

import { illustration, greeting } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Greeting() {
  const { isDark } = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className={isDark ? "dark-mode greeting-text" : "greeting-text"}
              >
                {/* <span className="greeting-text-p">{"Hello, my name is"}</span>
                <br></br> */}
                {"Hey, I'm"}
                {" "}
                {greeting.title}{" "}
                <span className="wave-emoji">{emoji("👋")}</span>
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {greeting.subTitle}
              </p>
              <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Contact me" href="#contact" />
                {greeting.resumeLink && (
                  <Button
                    text="See my resume"
                    newTab={true}
                    href={greeting.resumeLink}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="greeting-image-div" style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            {
              // illustration.animated ? (
              //   <DisplayLottie animationData={landingPerson} />
              // ) : 
              (
                <img
                  alt="A picture of me"
                  src={require("../../assets/images/pictureOfMe.jpg")}
                  
                  // style={{ maxHeight: "80vh", maxWidth: "100%", height: "auto", borderRadius: "1%", marginLeft: "20px"}}
                  // also give the image rounded corners and a shadow of a few pixels
                  className="picture-of-me"
                // give the image rounded corners and a shadow of a few pixels using teh style keyword
                // style={{borderRadius: "50%", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px"}}

                />
                // <img
                //   alt="A picture of me"
                //   src={require("../../assets/images/pictureOfMe.jpg"
                //   style={})}
                //   // give my image a set size
                //   // <img
                //   // alt="A picture of me"
                //   // src={require("../../assets/images/pictureOfMe.jpg")}
                //   // style={{width: "100%", height: "100%"}}
                //   />

                // ></img>
              )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
